import useApiService from '@/services/api.service'

export function useAccountsService() {
  const apiService = useApiService()
  const getMe = () => apiService.get('/users/me')

  const resetInviteLink = async () => apiService.post('/accounts/reset-invite-link', {})
  return {
    getMe,
    resetInviteLink,
  }
}
