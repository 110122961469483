'use client'

import axios from 'axios'
import { IDA_TOKEN } from '@/constants/constant'
import { message } from 'antd'
import { useRouter } from 'next/navigation'

const API_URL =
  process.env.NEXT_PUBLIC_API_BASE_URL || 'http://localhost:3000/v1'

const createApiService = router => {
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  api.interceptors.request.use(config => {
    const token = localStorage.getItem(IDA_TOKEN)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  function APIError({ error }) {
    return (
      <div className="inline-flex flex-wrap max-w-sm text-sm text-left">
        {error?.response?.data?.message}
      </div>
    )
  }

  api.interceptors.response.use(
    response => response.data,
    error => {
      if (error.response) {
        const { status } = error.response
        if (status === 401) {
          localStorage.clear()
          router.push('/?=Unauthorized')
        } else {
          message.error({
            content: <APIError error={error} />,
            className: 'ant-error-notification',
            duration: 10,
          })
        }
      }
      throw error.response
    },
  )

  return {
    get: (url, options = {}) => api.get(url, options),
    post: (url, data, options = {}) => api.post(url, data, options),
    put: (url, data, options = {}) => api.put(url, data, options),
    delete: (url, options = {}) => api.delete(url, options),
    apiUrl: API_URL,
  }
}

const useApiService = () => {
  const router = useRouter()
  return createApiService(router)
}

export default useApiService
