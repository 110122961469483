'use client'

import Link from 'next/link'
import { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { useRouter } from 'next/navigation'

import { useAuthService } from '@/services/auth.service'
import { useAccountsService } from '@/services/accounts.service'
import { useAppContext } from '@/contexts/AppContext'
import { IDA_TOKEN } from '@/constants/constant'
import Public from '@/layouts/Public'
import { showNotification } from '@/constants/utils'
import LucideIcon from '@/components/common/LucideIcon'
import Account from '@/layouts/Account'

export default function Login() {
  const [loader, setLoader] = useState(false)
  const [lock, setLock] = useState('Lock')
  const authService = useAuthService()
  const accountsService = useAccountsService()
  const { setUser } = useAppContext()
  const Router = useRouter()

  const { setUser: setUserStore } = useAppContext()

  const onSubmit = async values => {
    setLoader(true)
    try {
      const response = await authService.login(values)
      if (typeof window !== 'undefined') {
        localStorage.setItem(IDA_TOKEN, response?.data)
      }
      const { data } = await accountsService.getMe()
      setUser(data)
      if (typeof window !== 'undefined') {
        setUserStore(data)
      }
      // on success response set token in storage and redirect to dashboard
      Router.push('/chat')
    } catch (error) {
      const { data } = error?.response ?? {}
      showNotification('Invalid Credentials', data?.message)
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      <title>Login - Identifee Chat</title>
      <Public>
        <Account heading="Welcome back!">
          <Form name="form_login" autoComplete="off" onFinish={onSubmit}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email.',
                  type: 'email',
                },
              ]}
            >
              <Input
                size="large"
                prefix={
                  <LucideIcon
                    name="Mail"
                    size={18}
                    wrap="site-form-item-icon text-gray-500"
                  />
                }
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your password.',
                },
              ]}
            >
              <Input.Password
                prefix={
                  <LucideIcon
                    name={lock}
                    size={18}
                    filled
                    wrap="site-form-item-icon transition transition-all text-gray-500"
                  />
                }
                type="password"
                placeholder="Password"
                size="large"
                visibilityToggle={{
                  onVisibleChange: visible => {
                    setLock(visible ? 'LockOpen' : 'Lock')
                  },
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={loader}
              block
              className="cursor-pointer"
            >
              Log In
            </Button>
            <div className="py-2 text-center">
              <Link
                className="inline-block text-sm cursor-pointer login-form-forgot"
                href="/forget-password"
              >
                Forgot your password?
              </Link>
            </div>
          </Form>
        </Account>
      </Public>
    </>
  )
}
