'use client'

import { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAccountsService } from '@/services/accounts.service'
import { IDA_TOKEN } from '@/constants/constant'

const AppContext = createContext({
  user: null,
  setUser: () => null,
  login: () => null,
  logout: () => null,
  templates: [],
  setTemplates: () => null,
})

export function AppProvider({ children }) {
  const [user, setUser] = useState(null) // Initialize with null or your default user state
  const [templates, setTemplates] = useState(null) // Initialize with null or your default user state

  const [token, setToken] = useState()

  const [hasToken, setHasToken] = useState(false)

  const [hasWindow, setHasWindow] = useState(false)

  const accountsService = useAccountsService()

  const login = userData => {
    setUser(userData)
  }

  const logout = () => {
    setUser(null)
    setTemplates([])
  }

  useEffect(() => {
    if (hasWindow) {
      setToken(localStorage.getItem(IDA_TOKEN))
    }
  }, [hasWindow])

  useEffect(() => {
    if (token) {
      setHasToken(true)
    }
  }, [token])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [hasWindow])

  const { data: userData } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await accountsService.getMe()
      return data
    },
    enabled: hasToken,
  })

  useEffect(() => {
    if (token && userData) {
      setUser(userData)
    }
  }, [user?.id, userData])

  return (
    <AppContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={useMemo(
        () => ({
          user,
          setUser,
          login,
          logout,
          templates,
          setTemplates,
        }),
        [user, templates],
      )}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  const context = useContext(AppContext)

  return context
}
